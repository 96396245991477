import React from "react";
import Backgroundimage from "../../assets/images/background/image-4.jpg";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-white.png";

function Main() {
    return (
        <>
            <footer className="main-footer">
                <div
                    className="image-layer"
                    style={{ backgroundImage: `url(${Backgroundimage})` }}
                >
                    {" "}
                </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div
                                    className="inner wow fadeInUp"
                                    data-wow-delay="0ms"
                                    data-wow-duration="1500ms"
                                >
                                    <div className="content">
                                        <div className="logo">
                                            <Link
                                                to="/"
                                                title="Party Richter Bala"
                                            >
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    title="Party Richter Bala"
                                                />
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>
                                                    Bahnhofstraße 70, 42781 Haan
                                                </li>
                                                <li>
                                                    <Link to="mailto:info@party-richter-bala.de">
                                                        info@party-richter-bala.de
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="tel:021293788003">
                                                        02129 3788003
                                                    </Link>
                                                </li>
                                                <li>
                                                    Büro: 9:00 Uhr bis 13:00 Uhr
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="separator">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div
                                    className="inner wow fadeInLeft"
                                    data-wow-delay="0ms"
                                    data-wow-duration="1500ms"
                                >
                                    <ul className="links">
                                        <li>
                                            <Link to="/">Startseite</Link>
                                        </li>
                                        <li>
                                            <Link to="/#ueber-uns">
                                                Über uns
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/buffet">Buffet</Link>
                                        </li>
                                        <li>
                                            <Link to="/a-la-carte">
                                                Á la carte
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/stehconvent">
                                                Stehconvent
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/fruehstueck">
                                                Frühstück
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kontakt">Kontakt</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div
                                    className="inner wow fadeInRight"
                                    data-wow-delay="0ms"
                                    data-wow-duration="1500ms"
                                >
                                    <ul className="links">
                                        <li>
                                            <Link to="https://www.facebook.com">
                                                Facebook
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com">
                                                Instagram
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.google.com/maps">
                                                Google Maps
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">
                            &copy; {new Date().getFullYear()} Party Richter Bala
                        </div>
                    </div>
                </div>
            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html">
                <span className="icon fa fa-angle-up"></span>
            </div>
        </>
    );
}

export default Main;
